<template>
    <PageHeader current="home"></PageHeader>
    <div class="page_body">
        <div class="top_box">
            <img src="../assets/images/home/top_bg.png" class="top_img">
            <!-- <div class="top_content">
                <div class="top_text_1">LNG产业数字化服务领导者</div>
                <div class="top_text_2">重构LNG产业产 / 运 / 销</div>
            </div> -->
        </div>
        <div class="common_title">
            <div class="title_ch">我们的业务</div>
            <div class="title_en">BUSINESS</div>
        </div>

        <div class="bus_box">
            <img class="bus_left_img" :hidden="selectedBus!=1" src="../assets/images/home/bus_bg.png">
            <img class="bus_left_img" :hidden="selectedBus!=2" src="../assets/images/home/bus_bg_1.png">
            <img class="bus_left_img" :hidden="selectedBus!=3" src="../assets/images/home/bus_bg_2.png">
            <img class="bus_left_img" :hidden="selectedBus!=4" src="../assets/images/home/bus_bg_3.jpg">
            <img class="bus_left_img" :hidden="selectedBus!=5" src="../assets/images/home/bus_bg_4.jpg">
            <div class="bus_right">
                <div class="bus_line">
                    <div class="bus_info" :hidden="selectedBus!=1">
                        <div class="bus_info_top">围绕企业侧、产业侧、政府侧提供清洁能源（LNG）、物联网SAAS平台、物流监控平台、产业数字金融以及园区综合服务等一体化的数字化解决方案。</div>
                        <!-- <div class="bus_info_icon_line">
                            <div class="bus_info_icon">
                                <img class="bus_info_icon_img" src="../assets/images/home/pro_1/icon_1.png">
                                <div>购气宝</div>
                            </div>
                            <div class="bus_info_icon">
                                <img class="bus_info_icon_img" src="../assets/images/home/pro_1/icon_2.png">
                                <div>LNG自营</div>
                            </div>
                            <div class="bus_info_icon">
                                <img class="bus_info_icon_img" src="../assets/images/home/pro_1/icon_3.png">
                                <div>中能金融</div>
                            </div>
                            <div class="bus_info_icon">
                                <img class="bus_info_icon_img" src="../assets/images/home/pro_1/icon_4.png">
                                <div>数智科技</div>
                            </div>
                            <div class="bus_info_icon">
                                <img class="bus_info_icon_img" src="../assets/images/home/pro_1/icon_5.png">
                                <div>产业数据</div>
                            </div>
                        </div> -->
                        <div class="bus_more_line">
                            <div class="more_btn" ><a href="https://www.golng.cn/" target="_blank" rel="noopener noreferrer">了解更多>></a></div>
                        </div>
                    </div>
                    <div class="bus_info_right" :hidden="selectedBus==1" @mouseover="onHoverBus(1)">
                        <img class="bus_info_right_img" src="../assets/images/home/bus_bg.png">
                        <div class="bus_info_right_bg"></div>
                        <div class="bus_info_right_content">
                            <img class="bus_info_right_content_img"  src="../assets/images/home/pro_icon_1.png">
                            <div class="bus_child_text bus_child_text_1">LNG产业互联网平台</div>
                        </div>
                    </div>

                    <div class="bus_info" :hidden="selectedBus!=2">
                        <div class="bus_info_top">用科技链接金融与产业,为产融两端提供一揽子供应链金融综合解决方案。</div>
                        <!-- <div class="bus_info_icon_line">
                           
                            <div class="bus_info_icon">
                                <img class="bus_info_icon_img" src="../assets/images/home/pro_2/icon_1.png">
                                <div>中能E信</div>
                            </div>
                            <div class="bus_info_icon">
                                <img class="bus_info_icon_img" src="../assets/images/home/pro_2/icon_2.png">
                                <div>中能E采</div>
                            </div>
                            
                        </div> -->
                        <div class="bus_more_line">
                            <div class="more_btn" ><a href="http://finance.golng.cn/" target="_blank" rel="noopener noreferrer">了解更多>></a></div>
                        </div>
                    </div>

                    <div class="bus_info_right" :hidden="selectedBus==2" @mouseover="onHoverBus(2)">
                        <img class="bus_info_right_img" src="../assets/images/home/bus_bg_1.png">
                        <div class="bus_info_right_bg"></div>
                        <div class="bus_info_right_content">
                            <img class="bus_info_right_content_img" src="../assets/images/home/bus_big_1.png">
                            <div class="bus_child_text bus_child_text_1">LNG产业金融</div>
                        </div>
                    </div>
                </div>
                <div class="bus_line">
                    <div class="bus_info_small" :hidden="selectedBus!=3">
                        <div class="bus_info_top">依托科技的力量，为LNG产业用户提供最优的物联科技装备，解决产业存储、运输助力赋能,实现精益生产、敏捷管理和产业协同。</div>
                        <!-- <div class="bus_info_icon_line_small">
                           
                            <div class="bus_info_icon_small" style="width:24%">
                                <img class="bus_info_icon_img" src="../assets/images/home/pro_3/icon_1.png">
                                <div>PLC控制柜</div>
                            </div>
                            <div class="bus_info_icon_small" style="width:24%">
                                <img class="bus_info_icon_img" src="../assets/images/home/pro_3/icon_2.png">
                                <div>R-4-400 RTU</div>
                            </div>
                            <div class="bus_info_icon_small" style="width:24%">
                                <img class="bus_info_icon_img" src="../assets/images/home/pro_3/icon_3.png">
                                <div>R-4-200 RTU</div>
                            </div>
                            <div class="bus_info_icon_small" style="width:24%">
                                <img class="bus_info_icon_img" src="../assets/images/home/pro_3/icon_4.png">
                                <div>差压压力二<br/>合一传感器</div>
                            </div>
                            
                        </div> -->
                        <div class="bus_more_line">
                            <div class="more_btn" ><a href="http://digitalize.golng.cn/" target="_blank" rel="noopener noreferrer">了解更多>></a></div>
                        </div>
                    </div>

                    <div class="bus_child" :hidden="selectedBus==3" @mouseover="onHoverBus(3)">
                        <img class="bus_child_img" src="../assets/images/home/bus_bg_2.png">
                        <div class="bus_child_bg"></div>
                        <div class="bus_child_content">
                            <img class="bus_child_content_img" src="../assets/images/home/bus_big_2.png">
                            <div class="bus_child_text">LNG产业数字化</div>
                        </div>
                    </div>

                    <div class="bus_info_small" :hidden="selectedBus!=4">
                        <div class="bus_info_top">赋能数据，助力产业智慧转型，变革管理、生产和经营方式，提升生产力效率和管理水平，降低企业运营成本</div>
                        <!-- <div class="bus_info_icon_line_small">
                            <div class="bus_info_icon_small">
                                <img class="bus_info_icon_img" src="../assets/images/home/pro_4/icon_1.png">
                                <div>LNG贸易<br/>管理系统</div>
                            </div>
                            <div class="bus_info_icon_small">
                                <img class="bus_info_icon_img" src="../assets/images/home/pro_4/icon_2.png">
                                <div>LNG物流<br/>监控系统</div>
                            </div>
                            <div class="bus_info_icon_small">
                                <img class="bus_info_icon_img" src="../assets/images/home/pro_4/icon_3.png">
                                <div>LNG物联<br/>管理系统</div>
                            </div>
                            
                        </div> -->
                        <div class="bus_more_line">
                            <div class="more_btn" ><a href="http://iot.golng.cn/" target="_blank" rel="noopener noreferrer">了解更多>></a></div>
                        </div>
                    </div>

                    <div class="bus_child" :hidden="selectedBus==4" @mouseover="onHoverBus(4)">
                        <img class="bus_child_img" src="../assets/images/home/bus_bg_3.jpg">
                        <div class="bus_child_bg"></div>
                        <div class="bus_child_content">
                            <img class="bus_child_content_img" src="../assets/images/home/bus_big_3.png">
                            <div class="bus_child_text">LNG产业物联科技装备</div>
                        </div>
                    </div>

                    <div class="bus_info_small" :hidden="selectedBus!=5">
                        <div class="bus_info_top">依托海量的产业数据，致力于为企业和公共机构提供产业策略服务、产业数字化平台开发、产业数字化营销、产业人才培养服务。</div>
                        <!-- <div class="bus_info_icon_line_small">
                           
                            <div class="bus_info_icon_small" style="width:24%">
                                <img class="bus_info_icon_img" src="../assets/images/home/pro_5/icon_1.png">
                                <div>产业策略<br/>服务</div>
                            </div>
                            <div class="bus_info_icon_small" style="width:24%">
                                <img class="bus_info_icon_img" src="../assets/images/home/pro_5/icon_2.png">
                                <div>产业数字<br/>化平台开发</div>
                            </div>
                            <div class="bus_info_icon_small" style="width:24%">
                                <img class="bus_info_icon_img" src="../assets/images/home/pro_5/icon_3.png">
                                <div>产业数字<br/>化营销</div>
                            </div>
                            <div class="bus_info_icon_small" style="width:24%">
                                <img class="bus_info_icon_img" src="../assets/images/home/pro_5/icon_4.png">
                                <div>产业人才<br/>培养服务</div>
                            </div>
                            
                        </div> -->
                        <div class="bus_more_line">
                            <div class="more_btn" ><a href="http://www.nenghuaku.com/" target="_blank" rel="noopener noreferrer">了解更多>></a></div>
                        </div>
                    </div>


                    <div class="bus_child" :hidden="selectedBus==5" @mouseover="onHoverBus(5)">
                        <!-- <div class="bus_child_bg_last"></div> -->
                        <img class="bus_child_img" src="../assets/images/home/bus_bg_4.jpg">
                        <div class="bus_child_bg"></div>
                        <div class="bus_child_content">
                            <img class="bus_child_content_img" src="../assets/images/home/bus_big_4.png">
                            <div class="bus_child_text">LNG产业智库</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="common_title">
            <div class="title_ch">核心产品</div>
            <div class="title_en">CORE PRODUCTS</div>
        </div>

        <div class="pro_box" >
            <div class="pro_left" :hidden="selectedPro!=1&&selectedPro!=0">
                <img class="pro_left_bg"  src="../assets/images/home/pro_bg.png">
                <div class="pro_left_detail">
                    <div class="pro_left_top">
                        <img class="pro_left_img_top" src="../assets/images/home/pro_1/icon_1.png">
                        <div class="pro_left__top_text">购气宝</div>
                    </div>
                    <img class="pro_left_line" src="../assets/images/home/pro_line_4.png">
                    <div class="pro_left_child">
                        <!-- <div class="pro_left_child_item">
                            <img class="pro_left_child_img" src="../assets/images/home/pro_1/icon_1.png">
                            <div class="pro_left_text">购气宝</div>
                        </div> -->
                        <div class="pro_left_child_item">
                            <img class="pro_left_child_img" src="../assets/images/home/pro_1/icon_2.png">
                            <div class="pro_left_text">LNG自营</div>
                        </div>
                        <div class="pro_left_child_item">
                            <img class="pro_left_child_img" src="../assets/images/home/pro_1/icon_3.png">
                            <div class="pro_left_text">中能金融</div>
                        </div>
                        <div class="pro_left_child_item">
                            <img class="pro_left_child_img" src="../assets/images/home/pro_1/icon_4.png">
                            <div class="pro_left_text">产业数字化</div>
                        </div>
                        <div class="pro_left_child_item">
                            <img class="pro_left_child_img" src="../assets/images/home/pro_1/icon_5.png">
                            <div class="pro_left_text">产业数据</div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="pro_left" :hidden="selectedPro!=2">
                <img class="pro_left_bg"  src="../assets/images/home/pro_bg_1.png">
                <div class="pro_left_detail">
                    <div class="pro_left_top">
                        <img class="pro_left_img_top" src="../assets/images/home/pro_icon_2.png">
                        <div class="pro_left__top_text">中能金融</div>
                    </div>
                    <img class="pro_left_line" src="../assets/images/home/pro_line_2.png">
                    <div class="pro_left_child">
                        <div class="pro_left_child_item"></div>
                        <div class="pro_left_child_item">
                            <img class="pro_left_child_img" src="../assets/images/home/pro_2/fin_1.png">
                            <div class="pro_left_text">中能E信</div>
                        </div>
                        <div class="pro_left_child_item">
                            <img class="pro_left_child_img" src="../assets/images/home/pro_2/fin_2.png">
                            <div class="pro_left_text">中能E采</div>
                        </div>
                        <div class="pro_left_child_item"></div>
                    </div>
                </div>
            </div>
            <div class="pro_left" :hidden="selectedPro!=3">
                <img class="pro_left_bg"  src="../assets/images/home/pro_bg_2.png">
                <div class="pro_left_detail">
                    <div class="pro_left_top">
                        <img class="pro_left_img_top" src="../assets/images/home/pro_3/icon_top.png">
                        <div class="pro_left__top_text">智能硬件</div>
                    </div>
                    <img class="pro_left_line" src="../assets/images/home/pro_line.png">
                    <div class="pro_left_child">
                        <div class="pro_left_child_item">
                            <img class="pro_left_child_img" src="../assets/images/home/pro_3/icon_1.png">
                            <div class="pro_left_text">PLC控制柜</div>
                        </div>
                        <div class="pro_left_child_item">
                            <img class="pro_left_child_img" src="../assets/images/home/pro_3/icon_2.png">
                            <div class="pro_left_text">R-4-400 RTU</div>
                        </div>
                        <div class="pro_left_child_item">
                            <img class="pro_left_child_img" src="../assets/images/home/pro_3/icon_3.png">
                            <div class="pro_left_text">R-4-200 RTU</div>
                        </div>
                        <div class="pro_left_child_item">
                            <img class="pro_left_child_img" src="../assets/images/home/pro_3/icon_4.png">
                            <div class="pro_left_text">差压压力二合一传感器</div>
                        </div>
                        <div class="pro_left_child_item">
                            <img class="pro_left_child_img" src="../assets/images/home/pro_3/icon_2.png">
                            <div class="pro_left_text">…</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pro_left" :hidden="selectedPro!=4">
                <img class="pro_left_bg"  src="../assets/images/home/pro_bg_3.png">
                <div class="pro_left_detail">
                    <div class="pro_left_top">
                        <img class="pro_left_img_top" src="../assets/images/home/pro_4/icon_top.png">
                        <div class="pro_left__top_text">LNG智慧管理系统</div>
                    </div>
                    <img class="pro_left_line"  src="../assets/images/home/pro_line_3.png">
                    <div class="pro_left_child" style="width:77%">
                        <div class="pro_left_child_item" >
                            <img class="pro_left_child_img" src="../assets/images/home/pro_4/icon_1.png">
                            <div class="pro_left_text">LNG贸易管理系统</div>
                        </div>
                        <div class="pro_left_child_item" >
                            <img class="pro_left_child_img" src="../assets/images/home/pro_4/icon_2.png">
                            <div class="pro_left_text">LNG物流监控系统</div>
                        </div>
                        <div class="pro_left_child_item" >
                            <img class="pro_left_child_img" src="../assets/images/home/pro_4/icon_4.png">
                            <div class="pro_left_text">LNG物联管理系统</div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="pro_left" :hidden="selectedPro!=5">
                <img class="pro_left_bg"  src="../assets/images/home/pro_bg_4.png">
                <div class="pro_left_detail">
                    <div class="pro_left_top">
                        <img class="pro_left_img_top" src="../assets/images/home/pro_5/icon_top.png">
                        <div class="pro_left__top_text">能化库</div>
                    </div>
                    <img class="pro_left_line" src="../assets/images/home/pro_line_4.png">
                    <div class="pro_left_child">
                        <div class="pro_left_child_item">
                            <img class="pro_left_child_img" src="../assets/images/home/pro_5/icon_1.png">
                            <div class="pro_left_text">产业创新咨询</div>
                        </div>
                        <div class="pro_left_child_item">
                            <img class="pro_left_child_img" src="../assets/images/home/pro_5/icon_2.png">
                            <div class="pro_left_text">数字化平台建设运营</div>
                        </div>
                        <div class="pro_left_child_item">
                            <img class="pro_left_child_img" src="../assets/images/home/pro_5/icon_3.png">
                            <div class="pro_left_text">产业数字营销</div>
                        </div>
                        <div class="pro_left_child_item">
                            <img class="pro_left_child_img" src="../assets/images/home/pro_5/icon_4.png">
                            <div class="pro_left_text">产业人才培养</div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="pro_right">
                <div :class="['pro_item',selectedPro==1||selectedPro==0?'pro_item_1':'']"  @mouseover="onHoverPro(1)" style="border-top: none;">
                    <img class="pro_item_img" src="../assets/images/home/pro_icon_1.png">
                    <div class="pro_item_text">LNG产业互联网平台</div>
                </div>
                <div :class="['pro_item',selectedPro==2||selectedPro==0?'pro_item_2':'']" @mouseover="onHoverPro(2)">
                    <img class="pro_item_img" src="../assets/images/home/pro_icon_2.png">
                    <div class="pro_item_text">LNG产业金融</div>
                </div>
                <div :class="['pro_item',selectedPro==3||selectedPro==0?'pro_item_1':'']" @mouseover="onHoverPro(3)">
                    <img class="pro_item_img" src="../assets/images/home/pro_icon_3.png">
                    <div class="pro_item_text">LNG产业物联科技装备</div>
                </div>
                <div :class="['pro_item',selectedPro==4||selectedPro==0?'pro_item_2':'']" @mouseover="onHoverPro(4)">
                    <img class="pro_item_img" src="../assets/images/home/pro_icon_4.png">
                    <div class="pro_item_text">LNG产业数字化</div>
                </div>
                <div :class="['pro_item',selectedPro==5||selectedPro==0?'pro_item_1':'']" @mouseover="onHoverPro(5)">
                    <img class="pro_item_img" src="../assets/images/home/pro_icon_5.png">
                    <div class="pro_item_text">LNG产业智库</div>
                </div>
            </div>
        </div>

        <div class="common_title">
            <div class="title_ch">平台数据</div>
            <div class="title_en">PLATFORM DATA</div>
        </div>

        <div class="data_box">
            <div class="data_title">
                <div class="data_title_ch">平台数据</div>
                <div class="data_title_en">PLATFORM DATA</div>
            </div>
            <div class="data_line">
                <div class="data_item_1">
                    <div class="data_item_title">累计成交金额</div>
                    <div class="data_item_value">{{num1}}亿</div>
                </div>
                <div class="data_item_apart"></div>
                <div class="data_item_1">
                    <div class="data_item_title">年度成交气量</div>
                    <div class="data_item_value">{{num2}}万吨+</div>
                </div>
                <div class="data_item_apart"></div>
                <div class="data_item_1">
                    <div class="data_item_title">注册用户</div>
                    <div class="data_item_value">{{num3}}+</div>
                </div>
            </div>
            <div class="data_line">
                <div class="data_item_2">
                    <div class="data_item_title">服务站点</div>
                    <div class="data_item_value">{{num4}}+</div>
                </div>
                <div class="data_item_apart"></div>
                <div class="data_item_2">
                    <div class="data_item_title">短视频播放量</div>
                    <div class="data_item_value">{{num5}}亿+</div>
                </div>
            </div>
        </div>
        
        <div class="company_info">
            <div class="company_left">
                <div class="company_name">
                    <div class="company_name_ch">苏州中能鼎立科技有限公司</div>
                    <div class="company_name_en">Suzhou Zhongneng Dingli Technology Co., Ltd</div>
                </div>
                <div class="company_info_content">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;苏州中能鼎立科技有限公司作为液态大宗领域的产业互联网平台，团队依托多年在物联网、人工智能、云计算、GIS等领域长期积累的实践经验，已实现在全国5大片区多家分子公司的布局。公司旗下购气宝平台作为垂直天然气产业链的综合运营服务平台，以物联网、大数据、云计算及SAAS服务为基础，围绕企业侧、产业侧、政府侧提供清洁能源（LNG）、物联网SAAS平台、B2B交易平台、产业数字金融以及园区综合服务等一体化的数字化解决方案。截止目前为止，购气宝平台累计注册用户达6400家，年度累计成交金额50亿元，年度成交气量140万吨以上，累计服务站点达15000余家。
                </div>
                <div class="company_info_bottom">
                    <div @click="gotoAbout">了解更多>></div>
                </div>
            </div>
            <video class="company_video" poster="../assets/images/home/video_poster.png" controls src="../assets/images/home/video.mp4"></video>
        </div>
    </div>
    <PageFooter/>
</template>

<script>

import  PageHeader  from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import {util} from "@/common/util.js";
export default{
    components:{
        PageHeader,
        PageFooter
    },
    data(){
        return{
            selectedBus:1,
            selectedPro:0,
            num1:0,
            num2:0,
            num3:3000,
            num4:10000,
            num5:0
        }
    },
    mounted(){
        
         let interval1= setInterval(()=>{
                if(this.num1>=50){
                    clearInterval(interval1);
                }else{
                    this.num1 = this.num1+1;
                }
                
            },50);
           
            let interval2= setInterval(()=>{
                if(this.num2>=140){
                    clearInterval(interval2);
                }else{
                    this.num2 = this.num2+1;
                }
                
            },30);

            let interval3= setInterval(()=>{
                if(this.num3>=6400){
                    clearInterval(interval3);
                }else{
                    this.num3 = this.num3+4;
                }
                
            },1);

            let interval4= setInterval(()=>{
                if(this.num4>=15000){
                    clearInterval(interval4);
                }else{
                    this.num4 = this.num4+4;
                }
                
            },1);

            let interval5= setInterval(()=>{
                if(this.num5>=1){
                    clearInterval(interval5);
                }else{
                    this.num5 = this.num5+1;
                }
                
            },1000);
        
    },
    beforeDestory(){
        
    },
    methods:{

        gotoAbout(){
            this.$router.push('/about');
        },
        onHoverBus(id){
            this.selectedBus = id;
        },
        onHoverPro(id){
            this.selectedPro = id;
        }

    }
}
</script>

<style lang="css" scoped>
    .page_body{
      overflow-x:hidden;
    }

    .top_box{
        width:100%;
    }
    .top_img{
        width:100%;
        position: relative;
    }
    .top_content{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .top_text_1{
        color: #fff;
        font-size: 4.8vw;
        margin-top:-45%;
        font-weight: bold;
        background-color: rgb(49,114,183,0.3);
        border-radius: 5vw;
        box-shadow: 0 0 10px 10px rgb(49,114,183,0.3);
    }
    .top_text_2{
        background-color: #2b6db2;
        color: #fff;
        font-size: 2.8vw;
        padding:0 3vw;
        border-radius: 3vw;
        margin-top:1.4vw;
    }
    .common_title{
        margin:1vw 8vw;
        display: flex;
        flex-direction: row;
        align-items:flex-end;
    }
    .title_ch{
        font-size: 2vw;
        color:#333;
        border-bottom: #ffb371 solid 0.3vw;
    }

    .title_en{
        font-size: 1.6vw;
        color:#333;
        margin-left: 1vw;
        border-bottom: #fff solid 0.2vw;
    }

    @media screen and (max-width:768px){
        .common_title{
            margin:3vw 8vw;
        }

        .title_ch{
            font-size: 5vw;
            border-bottom: #ffb371 solid 0.8vw;
        }

        .title_en{
            font-size: 4vw;
            margin-left: 2vw;
            border-bottom: #fff solid 0.8vw;
        }
     }

    .bus_box{
        width:100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    @media screen and (max-width:768px){
        .bus_box{
            flex-wrap: wrap;
        }
     }
    .bus_left_img{
        width:45vw;
        height: 32vw;
    }
    @media screen and (max-width:768px){
        .bus_left_img{
            /* width:100vw;
            height: auto !important; */
            display: none;
        }
     }

    .bus_right{
        width:55vw;
    }

    @media screen and (max-width:768px){
        .bus_right{
            width:300vw;
            display: flex;
            flex-direction: row;
            overflow-x: scroll;
            
        }
     }

    .bus_line{
        display: flex;
        flex-direction: row;
        
    }
    .bus_info{
        width:27.5vw;
        height: 16vw;
        background-color: #1b6ae1;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
    }
    @media screen and (max-width:768px){
      
        .bus_info{
            width:60vw;
            height: 45vw;
        }
     }

    .bus_info_right{
        width:27.5vw;
        height: 16vw;
    }

    @media screen and (max-width:768px){
        .bus_info_right{
            width:60vw;
            height: 45vw;
        }
     }

    .bus_info_right_img{
        width:100%;
        height: 100%;
        position: relative;
    }
    .bus_info_right_bg{
        width:100%;
        height: 100%;
        position: relative;
        margin-top: -16vw;
        background-color: #ff5900;
        opacity: 0.7;
    }
    @media screen and (max-width:768px){
        .bus_info_right_bg{
            margin-top: -45vw;
        }
     }

    .bus_info_right_content{
        width:100%;
        height: 100%;
        position: relative;
        margin-top: -16vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
    }
    @media screen and (max-width:768px){
        .bus_info_right_content{
            margin-top: -45vw;
        }
     }

    .bus_info_right_content_img{
        width:3.4vw;
    }
    @media screen and (max-width:768px){
        .bus_info_right_content_img{
            width:10vw;
        }
     }

      .bus_info_small{
        width:18.33vw;
        height: 16vw;
        background-color: #1b6ae1;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
    }

    .bus_child{
        width:18.33vw;
        height: 16vw;
    }
    @media screen and (max-width:768px){
        .bus_info_small{
            width:60vw;
            height: 45vw;
        }
        .bus_child{
            width:60vw;
            height: 45vw;
        }
     }
    .bus_child_img{
        width:100%;
        height: 100%;
        position: relative;
    }
    .bus_child_bg{
        width:100%;
        height: 100%;
        position: relative;
        margin-top: -16vw;
        background-color: #ff5900;
        opacity: 0.7;
    }
    @media screen and (max-width:768px){
        .bus_child_bg{
            margin-top: -45vw;
        }
     }

    .bus_child_bg_last{
        width:100%;
        height: 100%;
        position: relative;
        background-color: #ff5900;
    }
    .bus_child_content{
        width:100%;
        height: 100%;
        position: relative;
        margin-top: -16vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
    }
    @media screen and (max-width:768px){
        .bus_child_content{
            margin-top: -45vw;
        }
     }
    .bus_child_content_img{
        height:3.4vw;
    }
    @media screen and (max-width:768px){
        .bus_child_content_img{
            height:10vw;
        }
     }
    .bus_child_text{
        font-size: 1.5vw;
        color:#fff;
        margin-top: 1vw;
    }

    @media screen and (max-width:768px){
        .bus_child_text_1{
            font-size: 5vw;
            margin-top: 3vw;
        }
        .bus_child_text{
            font-size: 5vw;
            margin-top: 3vw;
        }
     }

    .bus_info_top{
        padding:1.5vw;
        font-size: 1vw;
    }
  
    @media screen and (max-width:768px){
        .bus_info_top{
            padding:3vw 3vw 0 3vw;
            font-size: 3vw;
        }
      
     }


    .bus_info_icon_line{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    .bus_info_icon_line_small{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-around;
        padding:0 0.5vw;
    }
    .bus_info_icon_img{
        width:20px;
        margin-bottom: 0.2vw;
    }
    .bus_info_icon{
        font-size: 0.9vw;
        color: #fff;
        display: flex;
        flex-direction:column;
        align-items: center;
    }

    .bus_info_icon_small{
        font-size: 0.6vw;
        color: #fff;
        display: flex;
        flex-direction:column;
        align-items: center;
    }
    
    .bus_more_line{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
    }
    .more_btn{
        font-size: 1vw;
        border: #ffffff solid 1px;
        margin-right: 1.5vw;
        padding:0 5px;
        margin-bottom: 0.5vw;
        cursor: pointer;
    }
    .more_btn a {
        color: #ffffff;
    }

    .more_btn:hover{
        background-color: #ff5900;
        border: #ff5900 solid 1px;
    }
    .pro_box{
        width:100%;
        display: flex;
        flex-direction: row;
    }
    .pro_left{
        width:70%;
        height: 35.7vw;
        /* background: url('../assets/images/home/pro_bg.png') no-repeat 100% 100%; */
    }

    @media screen and (max-width:768px){
        .bus_info_icon_img{
            margin-bottom: 2vw;
        }
        .more_btn{
            margin-right: 5vw; 
            font-size: 3vw;
        }
        .pro_box{
            flex-wrap: wrap;
        }
        .pro_left{
            width: 100%;
            height: 65vw;
        }
     }

    .pro_left_bg{
        width:100%;
        height: 100%;
        position: relative;
    }
    .pro_left_detail{
        width:100%;
        height: 35.7vw;
        position: relative;
        margin-top: -35.7vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .pro_left_top{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #ffffff;
    }
    .pro_left_img_top{
        height:4vw;
        margin-bottom: 0.5vw;
    }
    .pro_left_child_img{
        width:2.6vw;
        margin-bottom: 0.5vw;
        transition: all .5s;
    }
    .pro_left_child_img:hover{
        transform: rotate(360deg);
    }
    .pro_left__top_text{
        font-size: 1.6vw;
    }
    .pro_left_text{
        font-size: 1.2vw;
    }
    .pro_left_line{
        width:81%;
    }
    .pro_left_child{
        width:100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }
    .pro_left_child_item{
        width:20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #ffffff;
    }
    .pro_right{
        width:30%;
    }
    .pro_item{
        cursor: pointer;
        width:100%;
        background-color: #999;
        color: #fff;
        font-size: 1.6vw;
        height: 7.14vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-left: solid 2px #fff;
        border-top: solid 2px #fff;
    }
    
    .pro_item_1{
        background-color: #1b6ae1;
        color: #fff;
    }
    .pro_item_2{
        background-color: #ff7800;
        color: #fff;
    }
   

    .pro_item_img{
        width:3vw;
        margin:0 1vw 0 2vw;
    }

    @media screen and (max-width:768px){
        .bus_info_icon{
            font-size: 3vw;
        }
        .bus_info_icon_small{
            font-size: 3vw;
        }
        .pro_left_detail{
            height: 65vw;
            margin-top: -65vw;
        }
        .pro_left_img_top{
            height:8vw;
            margin-bottom: 2vw;
        }
        .pro_left_child_img{
            width:6vw;
            margin-bottom: 2vw;
        }
        .pro_left__top_text{
            font-size: 4vw;
        }
        .pro_left_text{
            font-size: 3vw;
        }
        .pro_right{
            width:100%;
        }

        .pro_item{
            font-size: 4vw;
            height:12vw;
        }
        .pro_item_img{
            width:6vw;
            margin:0 5vw 0 5vw;
        }
    }

    .data_box{
        width:100%;
        height: 42.5vw;
        background: url('../assets/images/home/data_bg.png') no-repeat;
        background-size:contain;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    @media screen and (max-width:768px){
        .data_box{
            height: 100vw;
            background-size: cover;
        }
    }

    .data_title{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top:2vw;
    }

    .data_title_ch{
        font-size: 2.2vw;
        color:#ffffff;
        border-bottom: #f69139 solid 0.3vw;
    }
    .data_title_en{
        font-size: 1vw;
        color:#ffffff;
    }
    @media screen and (max-width:768px){
        .data_title{
            margin:3vw 0;
        }
        .data_title_ch{
            font-size: 5vw;
            border-bottom: #f69139 solid 0.8vw;
        }
        .data_title_en{
            font-size: 2vw;
        }
    }

    .data_line{
        width:100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 1.6vw;
        padding:2.5vw 0;
    }
    .data_item_1{
        width:33%;
        text-align: center;
    }
    .data_item_2{
        width:33%;
        text-align: center;
    }

    .data_item_value{
        font-size: 3.6vw;
        font-weight: bold;
        color: #ff912f;
    }

    .data_item_apart{
        width:2px;
        height: 9vw;
        background-color: #fff;
    }

    @media screen and (max-width:768px){
        .data_line{
            font-size: 4vw;
            padding:5vw 0;
        }
        .data_item_value{
            font-size: 6vw;
        }
    }
    .company_info{
        background-color: #f5f5f5;
        display: flex;
        flex-direction: row;
        
        padding:3vw 0;
    }
    .company_left{
        width:50%;
        padding:0 5vw;
    }

    .company_video{
        width:46vw;
    }

    @media screen and (max-width:768px){
        .company_info{
            flex-wrap: wrap;
        }
        .company_left{
            width:100%;
        }
        .company_video{
            width:100%;
            margin: 3vw 0;
        }
     }

    .company_name{
        border-bottom: #f69139 solid 0.3vw;
        padding:0.4vw 0;
        color:#333;
    }
    .company_name_ch{
        font-size: 2.6vw;
        
    }
    @media screen and (max-width:768px){
        .company_name_ch{
            font-size: 5vw;
        }

        .company_name{
            padding:1vw 0;
            margin-bottom: 2vw;
        }
    }

    .company_name_en{
        font-size: 1.4vw;
    }
    .company_info_content{
        margin: 1vw 0;
        font-size: 1vw;
    }

    .company_info_bottom{
        text-align: right;
        border-bottom: solid 1px #aaa;
        color: #1b6ae1;
        margin: 4vw 0 2vw 0;
        padding-bottom: 1vw;
    }
    .company_info_bottom div{
        cursor: pointer;
    }
    @media screen and (max-width:768px){
       
        .company_info_content{
            font-size:3.4vw;
        }
        .company_name_en{
            font-size: 2vw;
        }
    }
</style>