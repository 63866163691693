<template>
    <div class="page_container_mobile" v-if="screenWidth<=1000" @click="closeMenu">
      <menu-unfold-outlined
          v-if="collapsed"
          class="trigger"
          @click.stop="() => (collapsed = !collapsed)"
        />
        <menu-fold-outlined v-else class="trigger" @click.stop="() => (collapsed = !collapsed)" />
      <img src="../assets/images/logo.png" class="logo_mobile"/>
    </div>
    
    <div class="menu_mobile" v-if="screenWidth<=1000 && collapsed">
      <div :class="['menu_item_mobile',current=='home'?'selected':'']" id="home" @click="gotoPage('/home')">
        首页
      </div>
      <div class="menu_item_mobile" id="lng-web" @click="closeMenu">
        <a href="https://www.golng.cn/" target="_blank" rel="noopener noreferrer">
          LNG产业互联网平台
        </a>
      </div>
      <div class="menu_item_mobile" id="lng-finance"  @click="closeMenu">
        <a href="http://finance.golng.cn/" target="_blank" rel="noopener noreferrer">
          LNG产业金融
        </a>
      </div>
      <div class="menu_item_mobile" id="lng-math"  @click="closeMenu">
        <a href="http://digitalize.golng.cn/" target="_blank" rel="noopener noreferrer">
          LNG产业数字化
        </a>
      </div>
      <div class="menu_item_mobile" id="lng-device"  @click="closeMenu">
        <a href="http://iot.golng.cn/" target="_blank" rel="noopener noreferrer">
          LNG产业物联科技装备
        </a>
      </div>
      <div class="menu_item_mobile" id="lng-smart"  @click="closeMenu">
        <a href="http://www.nenghuaku.com/" target="_blank" rel="noopener noreferrer">
          LNG产业智库
        </a>
      </div>
      <div :class="['menu_item_mobile',current=='about'?'selected':'']" id="about" @click="gotoPage('/about')">
        关于我们
      </div>
    </div>
    <div class="page_container" v-if="screenWidth>1000">
      <img src="../assets/images/logo.png" class="logo"/>
      <div class="menu_h" >
        <div :class="['menu_item',current=='home'?'selected':'']" id="home" @click="gotoPage('/home')">
          首页
        </div>
        <div class="menu_item" id="lng-web">
          <a href="https://www.golng.cn/" target="_blank" rel="noopener noreferrer">
            LNG产业互联网平台
          </a>
        </div>
        <div class="menu_item" id="lng-finance">
          <a href="http://finance.golng.cn/" target="_blank" rel="noopener noreferrer">
            LNG产业金融
          </a>
        </div>
        <div class="menu_item" id="lng-math">
          <a href="http://digitalize.golng.cn/" target="_blank" rel="noopener noreferrer">
            LNG产业数字化
          </a>
        </div>
        <div class="menu_item" id="lng-device">
          <a href="http://iot.golng.cn/" target="_blank" rel="noopener noreferrer">
            LNG产业物联科技装备
          </a>
        </div>
        <div class="menu_item" id="lng-smart">
          <a href="http://www.nenghuaku.com/" target="_blank" rel="noopener noreferrer">
            LNG产业智库
          </a>
        </div>
        <div :class="['menu_item',current=='about'?'selected':'']" id="about" @click="gotoPage('/about')">
          关于我们
        </div>
      </div>
    </div>
</template>

<script>
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons-vue';
 export default {
    name:'PageHeader',
    props: {
      current:{
        type:String,
        default:'home'
      }
    },
    components: {
      MenuUnfoldOutlined,
      MenuFoldOutlined,
    },
    watch:{
        
    },
    data() {
      return {
        screenWidth:window.innerWidth,
        collapsed:false
      };
    },
    mounted(){
      window.addEventListener("resize", this.handleResize);
    },
  
    methods:{
      handleResize(){
        this.screenWidth = window.innerWidth;
      },
      gotoPage(url){
        this.$router.push(url);
      },
      closeMenu(){
        this.collapsed = false;
      }
    }
  };
</script>

<style scoped>
.page_container_mobile{
  width:100%;
  height: 40px;
  background-color: #376ab3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.page_container{
  width:100%;
  height: 60px;
  background-color: #376ab3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.logo_mobile{
  height: 22px;
}

.logo{
  height: 32px;
  margin-right: 30px;
}
.menu_h{
  line-height: 60px;
  display: flex;
  flex-direction: row;

}


.menu_item,.menu_item > a{
  height: 60px;
  font-weight: bold;
  color:#fff;
  padding:0 10px;
  cursor: pointer;
}

.menu_item.selected{
  background-color: #ff912f;
}

.menu_item:hover,.menu_item:active{
  background-color: #ff912f;
}

.menu_mobile{
  width:50vw;
  position: fixed;
  left: 0;
  top:40px;
  z-index: 99;
}

.menu_item_mobile{
  font-weight: bold;
  color:#fff;
  padding:10px;
  cursor: pointer;
  background-color: #376ab3;
}
.menu_item_mobile > a{
  color:#fff;
}

.menu_item_mobile.selected{
  background-color: #ff912f;
}

.menu_item_mobile:hover,.menu_item_mobile:active{
  background-color: #ff912f;
}

.trigger{
  color: #fff;
  font-size: 22px;
  margin: 0 20px;
}



</style>