import { createWebHistory,createRouter } from "vue-router";
import Home from '@/pages/Home';
import About from '@/pages/About';

const history = createWebHistory();

const router = createRouter(
    {
        history:createWebHistory('/'),
        routes: 
        [
            {path:'/',component:Home},
            {path:'/home',component:Home},
            {path:'/about',component:About}
        ]
})

export default router;