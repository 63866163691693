<template>
  <div class="page_container">
    <div class="cr_code" style="margin-right: 40px;">
      <img src="../assets/images/code_1.jpg" class="code_img">
      <div>购气宝订阅号</div>
    </div>
    <div class="cr_code">
      <img src="../assets/images/code_2.jpg" class="code_img">
      <div>中能鼎立服务号</div>
    </div>
    <div class="footer_right">
      <div class="menu_line" style="margin-bottom: 1vw;">
        <div class="menu_item" id="lng-web">
            <a href="https://www.golng.cn/" target="_blank" rel="noopener noreferrer">
              LNG产业互联网平台
            </a>
          </div>
          <div class="menu_apart"></div>
          <div class="menu_item" id="lng-finance">
            <a href="http://finance.golng.cn" target="_blank" rel="noopener noreferrer">
              LNG产业金融
            </a>
          </div>
          <div class="menu_apart"></div>
          <div class="menu_item" id="lng-math">
            <a href="http://digitalize.golng.cn/" target="_blank" rel="noopener noreferrer">
              LNG产业数字化
            </a>
          </div>
          <div class="menu_apart"></div>
          <div class="menu_item" id="lng-device">
            <a href="http://iot.golng.cn" target="_blank" rel="noopener noreferrer">
              LNG产业物联科技装备
            </a>
          </div>
          <div class="menu_apart"></div>
          <div class="menu_item" id="lng-smart">
            <a href="http://www.nenghuaku.com/" target="_blank" rel="noopener noreferrer">
              LNG产业智库
            </a>
          </div>
      </div>
      <div class="phone">
        联系方式：13764642251
      </div>
      <div class="menu_line_small">
        <div class="menu_item_small">
            <a href="" target="_blank" rel="noopener noreferrer">
              站长统计
            </a>
        </div>
        <div class="menu_apart_small"></div>
        <div class="menu_item_small">
            <a href="" target="_blank" rel="noopener noreferrer">
              网站地图
            </a>
        </div>
        <div class="menu_apart_small"></div>
        <div class="menu_item_small">
            <a href="" target="_blank" rel="noopener noreferrer">
              法律声明
            </a>
        </div>
        <div class="menu_apart_small"></div>
        <div class="menu_item_small">
            <a href="" target="_blank" rel="noopener noreferrer">
              友情链接
            </a>
        </div>
      </div>
      <div class="beian">
        ©苏ICP备16041716号-3 Copyright © 苏州中能鼎立科技有限公司 版权所有
      </div>
    </div>
  </div>
  
</template>

<script>
export default {
  name:'PageFooter',
  props: {
    
      },
  watch:{
      
  },
  data() {
    return {
      
    };
  },
  mounted(){
  },

  methods:{
     
  }
};
</script>

<style scoped>
.page_container{
  width:100%;
  background-color: #376ab3;
  color:#ffffff;
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: center;
  flex-wrap: wrap;
  padding:3vw 0 4vw 0;
}
.cr_code{
  
  font-size: 0.9vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.code_img{
  width:100px;
  margin-bottom: 2px;
}

.footer_right{
  margin-left: 5vw;
  margin-right: 5vw;
  margin-top: 2vw;
}

.menu_line{
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.menu_line_small{
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.menu_item{
  font-size: 1vw;
}
.menu_item a{
  color: #ffffff;
}
.menu_item_small{
  font-size: 0.8vw;
  margin-top: 0.4vw;
}
.menu_item_small a{
  color: #ffffff;
}

.menu_apart{
  width:2px;
  height: 10px;
  background-color: #ffffff;
  margin:0 1.1vw;
}
.menu_apart_small{
  width:1px;
  height: 8px;
  background-color: #ffffff;
  margin:0 0.4vw;
  margin-top: 0.4vw;
}

.phone{
  font-size: 1.4vw;
  font-weight: bold;
  font-style: italic;
  padding:1.6vw 0;
  border-bottom: solid 1px #ffffff;
}

.beian{
  font-size: 0.8vw;
}

@media screen and (max-width:768px){
  .cr_code{
    font-size: 3vw;
  }
  .menu_line{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.menu_apart_small{
  justify-content:center;
}

  .menu_item{
    font-size: 3.6vw;
    border-bottom: solid 1px #ffffff;
    padding:2vw 0;
    width:100%;
    font-weight: bold;
  }
  
.menu_item_small{
  font-size: 3vw;
  margin-top: 1vw;
}

  .menu_apart{
    display: none;
  }

  .menu_apart_small{
    height: 3vw;
  margin:0 2vw;
  margin-top: 1vw;
}

  .phone{
    font-size: 4vw;
    margin: 3vw 0;
  }

  .beian{
      font-size: 2.6vw;
    }
}


</style>