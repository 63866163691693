<template>
    <PageHeader current="about"></PageHeader>
    <div class="page_body">
        <div class="top_box">
            <img src="../assets/images/about/top_bg.png" class="top_img">
            <div class="top_content">
                <div class="top_text_1">关于我们</div>
                <div class="top_text_2">ABOUT US</div>
            </div>
        </div>
        <div class="about_title">
            <div>
                公司概况
            </div>
        </div>
        <div class="company_box">
            <div class="company_left">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;苏州中能鼎立科技有限公司作为液态大宗领域的产业互联网平台，团队依托多年在物联网、人工智能、云计算、GIS等领域长期积累的实践经验，已实现在全国5大片区多家分子公司的布局。公司旗下购气宝平台作为垂直天然气产业链的综合运营服务平台，以物联网、大数据、云计算及SAAS服务为基础，围绕企业侧、产业侧、政府侧提供清洁能源（LNG）、物联网SAAS平台、B2B交易平台、产业数字金融以及园区综合服务等一体化的数字化解决方案。截止目前为止，购气宝平台累计注册用户达6400家，年度累计成交金额50亿元，年度成交气量140万吨以上，累计服务站点达15000余家。
               <p></p>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;未来，中能鼎立在深耕天然气产业链的同时会不断扩充品类，以满足能源全产业链用户需求。在不断探索国内液态大宗产业高质量发展模式的同时，加速推进产业数字化升级。以平台为基础，以南通总部为供应链智慧衔接中心，统一调配全国各片区资源，整合产业链上下游企业、科研机构、创新型企业等资源，打造产业生态系统，提高产业的协同和合作能力，做中国液态大宗市场化改革的推动者和践行者。
            </div>
            <!-- <img src="../assets/images/about/gsgk.png" class="com_img"> -->
            <video class="company_video" poster="../assets/images/home/video_poster.png" controls src="../assets/images/home/video.mp4"></video>
        </div>

        <img src="../assets/images/about/develop.png" class="develop_img">

        <div class="about_title">
            <div>
                合作生态&nbsp;&nbsp;&nbsp;&nbsp;共建产业未来
            </div>
        </div>
        <div class="logo_box">
            <div class="logo_line">
                <img src="../assets/images/logo/huicong.png">
                <div class="logo_apart"></div>
                <img src="../assets/images/logo/zhonghaiyou.png">
                <div class="logo_apart"></div>
                <img src="../assets/images/logo/zhongshihua.png">
                <div class="logo_apart"></div>
                <img src="../assets/images/logo/liqing.png">
                <div class="logo_apart"></div>
                <img src="../assets/images/logo/hongren.png">
                <div class="logo_apart"></div>
                <img src="../assets/images/logo/zhiyang.png">
            </div>
            <div class="logo_line">
                <img src="../assets/images/logo/zhongshiyou.png">
                <div class="logo_apart"></div>
                <img src="../assets/images/logo/huashidai.png">
                <div class="logo_apart"></div>
                <img src="../assets/images/logo/ganghua.png">
                <div class="logo_apart"></div>
                <img src="../assets/images/logo/huafeng.png">
                <div class="logo_apart"></div>
                <img src="../assets/images/logo/suneng.png">
                <div class="logo_apart"></div>
                <img src="../assets/images/logo/xinhuoyan.png">
            </div>
            <div class="logo_line">
                <img src="../assets/images/logo/huiteng.png">
                <div class="logo_apart"></div>
                <img src="../assets/images/logo/saifute.png">
                <div class="logo_apart"></div>
                <img src="../assets/images/logo/tianhong.png">
                <div class="logo_apart"></div>
                <img src="../assets/images/logo/gaojie.png">
                <div class="logo_apart"></div>
                <img src="../assets/images/logo/fuping.png">
                <div class="logo_apart"></div>
                <img src="../assets/images/logo/dongnan.png">
            </div>
            <div class="logo_line">
                <img src="../assets/images/logo/lunda.png">
                <div class="logo_apart"></div>
                <img src="../assets/images/logo/huayou.png">
                <div class="logo_apart"></div>
                <img src="../assets/images/logo/xinao.png">
                <div class="logo_apart"></div>
                <img src="../assets/images/logo/huarun.png">
                <div class="logo_apart"></div>
                <img src="../assets/images/logo/runde.png">
                <div class="logo_apart"></div>
                <img src="../assets/images/logo/chenggong.png">
            </div>
        </div>

        <div class="tech_box">
            <div class="about_title">
                <div>
                    科技创新&nbsp;&nbsp;&nbsp;&nbsp;引领产业数字决策
                </div>
            </div>
            <div class="tech_line">
                <div class="tech_item_1">
                    <img src="../assets/images/about/cert_1.png">
                    <div>江苏省民营科技企业</div>
                </div>
                <div class="tech_apart_area"></div>
                <div class="tech_item_1">
                    <img src="../assets/images/about/cert_2.png">
                    <div>江苏省民营科技企业</div>
                </div>
            </div>
            <div class="tech_line">
                <div class="tech_item_2">
                    <img src="../assets/images/about/cert_3.png">
                    <div>数据无线发收软著</div>
                </div>
                <div class="tech_item_2">
                    <img src="../assets/images/about/cert_4.png">
                    <div>天然气充装配送软著</div>
                </div>
                <div class="tech_item_2">
                    <img src="../assets/images/about/cert_5.png">
                    <div>天然气建站管理系统</div>
                </div>
                <div class="tech_item_2" style="margin-right: 0;">
                    <img src="../assets/images/about/cert_6.png">
                    <div>自主研发设备防爆合格证</div>
                </div>
            </div>
        </div>
        <div class="contact_us">
            <div class="contact_box">
                <div class="contact_title"><div>联系我们</div></div>
                <div class="company_name">苏州中能鼎立科技有限公司</div>
                <div class="contact_line"></div>
                <div class="contact_content">
                    <div>联系方式：<span class="phone_num">13764642251</span></div>
                    <div>总部地址：<span class="adress">苏州工业园区酝慧路168号16层</span></div>
                </div>
                <div id="container"></div>
                
            </div>
        </div>
    </div>
    <PageFooter></PageFooter>
</template>

<script>
 import AMap from 'AMap' // 引入高德地图
import AMapUI from 'AMapUI'
import  PageHeader  from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import {util} from "@/common/util.js";
export default{
    components:{
        PageHeader,
        PageFooter
    },
    data(){
        return{
            
        }
    },
    mounted(){
        this.initMap();
        
    },
    beforeDestory(){
        
    },
    methods:{
       initMap(){
            //创建地图
            var map = new AMap.Map('container', {
                center: [120.731733,31.248964],
                zoom: 15
            });

            AMapUI.loadUI(['overlay/SimpleMarker'], function(SimpleMarker) {


                    new SimpleMarker({
                        //显示定位基点
                        showPositionPoint: true,

                        iconStyle: 'blue',
                        map: map,
                        position: [120.731733,31.248964]
                    });
                });

            // AMap.plugin(['AMap.ToolBar'], function() {
            //     map.addControl(new AMap.ToolBar({
            //         map: map
            //     }));
            //  });

       },

}
}
</script>

<style lang="css" scoped>
     .page_body{
      overflow-x:hidden;
    }

    .top_box{
        width:100%;
    }
    .top_img{
        width:100%;
        position: relative;
    }
    .top_content{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
    }

    .top_text_1{
        color: #fff;
        font-size: 5vw;
        margin-top:-40%;
        font-weight: 500;
        background-color: rgb(49,114,183,0.3);
        border-radius: 5vw;
        box-shadow: 0 0 10px 10px rgb(49,114,183,0.3);
    }
    .top_text_2{
        color: #fff;
        font-size: 3.2vw;
        margin-top:1.2vw;
        background-color: rgb(49,114,183,0.3);
        border-radius: 3.2vw;
        box-shadow: 0 0 10px 10px rgb(49,114,183,0.3);
    }

    .about_title{
        width:100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding:3vw 0 3vw 0;
    }

    .about_title div{
        font-size: 2.4vw;
        color:#333;
        border-bottom: #ee7a00 solid 0.3vw;
    }
    @media screen and (max-width:768px){
        .about_title div{
            font-size: 5vw;
        }
     }

    
    .company_box{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding:0 3vw;
    }

    .company_left{
        margin: 1vw 2vw;
        font-size: 1vw;
        width:42vw;
    }
    @media screen and (max-width:768px){
        .company_left{
            margin: 1vw 2vw;
            font-size: 3.6vw;
            width:90vw;
        }
     }

    .com_img{
        width:42vw;
        margin: 0 2vw;
    }
    .company_video{
        width:46vw;
    }

    @media screen and (max-width:768px){
        .com_img{
        margin: 0 2vw;
        width:90vw;
    }
    .company_video{
            width:100%;
            margin: 3vw 0;
        }
    }

    .develop_img{
        width: 100%;
        margin-top: 4vw;
    }

    .logo_box{
        margin-top: 3vw;
    }

    .logo_line{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding-left:5vw;
    }
    .logo_line img{
        width:15vw;
        margin-top: 1vw;
    }
    .logo_apart{
        width:1px;
        height: 2vw;
        background-color: #eee;
    }

    .tech_box{
        background-color: #f5f5f5;
        margin-top:4vw;
        padding-bottom: 4vw;
    }
    .tech_line{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        font-size: 0.6vw;
        margin-top:2vw;
    }
    .tech_item_1{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .tech_item_1 img{
        width:20vw;
        margin-bottom: 1vw;
    }

    .tech_item_2{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 2vw;
    }

    .tech_item_2 img{
        width:14vw;
        margin-bottom: 1vw;
    }
     
    .contact_us{
        width:100%;
        padding:4vw 7vw;
    }
    .contact_box{
        width:86vw;
        border-top: #ee7a00 solid 0.3vw;
        box-shadow: 0 0 3px 3px rgba(161, 161, 161, .1);
    }
    .contact_title{
        font-size: 2.2vw;
        color: #333;
        margin: 1.6vw 3vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
    .contact_title div{
        border-bottom: #ee7a00 solid 0.3vw;
    }

    .company_name{
        margin: 1.2vw 3vw;
        font-size: 1.2vw;
        color: #333;
    }

    .contact_line{
        margin:1.2vw 3vw;
        width:2.4vw;
        height: 0.2vw;
        background-color: #217bc0;
    }

    .contact_content{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin: 0 3vw;
        color: #515151;
        font-size: 0.9vw;
    }
    .phone_num{
        color:#217bc0 ;
        font-size: 1.2vw;
        font-weight: 500;
        margin-right: 5vw;
    }
    .adress{
        color: #333;
        font-size: 1vw;
    }
    #container{
        width: 86vw; 
        height: 25vw;
        margin-top:2vw
    }

    .tech_apart_area{
        width:10vw;
    }

    @media screen and (max-width:768px){
        .tech_line{
            font-size: 3vw;
        }
    .tech_item_1 img{
        width:60vw;
        margin: 3vw 0;
    }
    .tech_apart_area{
        display: none;
    }
    .tech_item_2 img{
        width:40vw;
        margin: 3vw 0;
    }
        .logo_line img{
        width:30vw;
        margin-top: 1vw;
    }
    .logo_apart{
        display: none;
    }
    .company_name{
        margin: 1.2vw 3vw;
        font-size: 3.8vw;
    }
        .contact_title{
        font-size: 5vw;
        margin: 2vw 3vw;
    }
    .contact_line{
        margin:1.2vw 3vw;
        width:6vw;
        height: 0.5vw;
    }

        #container{
        height: 60vw;
    }

    .contact_content{
        margin: 0 3vw;
        font-size: 3.6vw;
        flex-direction: column;
        align-items: flex-start;
    }
    .phone_num{
        font-size: 4vw;
        font-weight: 500;
        margin-right: 5vw;
    }
    .adress{
        font-size: 3.6vw;
    }
    }
</style>